<template>
 <!-- <div class="view login" v-if="state.username === '' || state.username === null">
    <form class="login-form" @submit.prevent="Login">
      <div class="form-inner">
        <h1>Login to FireChat</h1>
        <label for="username">Username</label>
        <input 
          type="text" 
          v-model="inputUsername" 
          placeholder="Please enter your username..." />
        <input 
          type="submit" 
          value="Login" />
      </div>
    </form>
  </div> -->
  
  <div class="view chat">
    <header>
      <!-- <button class="logout" @click="Logout">Logout</button>
      <h1>{{ state.username }}</h1> -->
    </header>
    
    <section class="chat-box">
      <div 
        v-for="message in state.messages" 
        :key="message.key" 
        :class="(message.username == state.username ? 'message current-user' : 'message')">
        <div class="message-inner">
          <div class="username">{{ message.username }}</div>
          <div class="content">{{ message.content }}</div>
        </div>
      </div>
    </section>

    <footer>
        <v-text-field
        solo
        hide-details
        rounded
        elevation-12
		@keyup.enter="SendMessage"
        v-model="inputMessage"
        placeholder="Ketikan Pesan..."
        append-icon="mdi-send"
        @click:append="SendMessage"
        />
    </footer>
  </div>
</template>
<script>
// import ref from "firebase/app";
// import set from "firebase/app";
// import push from "firebase/app";
import db from "@/services/chat"


export default {
    data(){
        return{
             inputUsername:"",
             inputMessage: "",
             state :{
				username : null,
                messages:[]
            },
			
             
        }
    },
    mounted(){
	let karyawan = JSON.parse(localStorage.getItem('karyawan'))
	this.state.username = karyawan.Nama
	// this.setState()
	this.getChat()
    },
    methods:{
		// setState(){
		// 	this.state.username = this.$user.Nama
		// },
		scrollkebawah(){
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		},
		getChat(){
			let Pesan = db.database().ref("messages")
			Pesan.on('value', snapshot => {
				const data = snapshot.val();
				let messages = [];
				Object.keys(data).forEach(key => {
				messages.push({
					id: key,
					username: data[key].username,
					content: data[key].content
				});
				});
				this.state.messages = messages.reverse();
			});
			window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
		},
        Login(){
                if(this.inputUsername.value != "" || this.inputUsername.value != null){
                this.state.username = this.inputUsername
                this.inputUsername = ""
                }
            },
        Logout(){
            this.state.username = "";
        },
        SendMessage(){
        if (this.inputMessage === "" || this.inputMessage === null) {
            return;
        }
            const message = {
                username: this.state.username,
                content: this.inputMessage
            }
            // console.log(message)
            let msgref = db.database().ref("messages")
            msgref.push(message)
            // set(push(ref(db, 'messages')), message);
            this.inputMessage = "";
			this.scrollkebawah()
        }
    },
}
</script>

<style lang="scss">
* {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.view {
	display: flex;
	justify-content: center;
	min-height: 100vh;
	background-color: #df1d41;
	
	&.login {
		align-items: center;
		.login-form {
			display: block;
			width: 100%;
			padding: 15px;
			
			.form-inner {
				display: block;
				background-color: #FFF;
				padding: 50px 15px;
				border-radius: 16px;
				box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);

				h1 {
					color: #AAA;
					font-size: 28px;
					margin-bottom: 30px;
				}

				label {
					display: block;
					margin-bottom: 5px;
					color: #AAA;
					font-size: 16px;
					transition: 0.4s;
				}

				input[type="text"] {
					appearance: none;
					border: none;
					outline: none;
					background: none;

					display: block;
					width: 100%;
					padding: 10px 15px;
					border-radius: 8px;
					margin-bottom: 15px;
					
					color: #333;
					font-size: 18px;

					box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
					background-color: #F3F3F3;

					transition: 0.4s;

					&::placeholder {
						color: #888;
						transition: 0.4s;
					}
				}

				input[type="submit"] {
					appearance: none;
					border: none;
					outline: none;
					background: none;

					display: block;
					width: 100%;
					padding: 10px 15px;
					background-color: #333;
					border-radius: 8px;

					color: #FFF;
					font-size: 18px;
					font-weight: 700;
				}

				&:focus-within {
					label {
						color: #b61331;
					}

					input[type="text"] {
						background-color: #FFF;
						box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

						&::placeholder {
							color: #666;
						}
					}
				}
			}
		}
	}

	&.chat {
		flex-direction: column;

		header {
			position: relative;
			display: block;
			width: 100%;
			padding: 50px 30px 10px;

			.logout {
				position: absolute;
				top: 15px;
				right: 15px;
				appearance: none;
				border: none;
				outline: none;
				background: none;
				
				color: #FFF;
				font-size: 18px;
				margin-bottom: 10px;
				text-align: right;
			}

			h1 {
				color: #FFF;
			}
		}

		.chat-box {
			border-radius: 24px 24px 0px 0px;
			background-color: #FFF;
			box-shadow: 0px 0px 12px rgba(100, 100, 100, 0.2);
			flex: 1 1 100%;
			padding: 30px;

			.message {
				display: flex;
				margin-bottom: 15px;
				
				.message-inner {
					.username {
						color: #888;
						font-size: 16px;
						margin-bottom: 5px;
						padding-left: 15px;
						padding-right: 15px;
					}

					.content {
						display: inline-block;
						padding: 10px 20px;
						background-color: #F3F3F3;
						border-radius: 999px;

						color: #333;
						font-size: 18px;
						line-height: 1.2em;
						text-align: left;
					}
				}

				&.current-user {
					margin-top: 30px;
					justify-content: flex-end;
					text-align: right;

					.message-inner {
						max-width: 75%;

						.content {
							color: #FFF;
							font-weight: 600;
							background-color: #9e0e28cb;
						}
					}
				}
			}
		}

		footer {
			position: sticky;
			bottom: 0px;
			background-color: #FFF;
			padding: 30px;
			box-shadow: 0px 0px 12px rgba(100, 100, 100, 0.2);

			form {
				display: flex;

				input[type="text"] {
					flex: 1 1 100%;

					appearance: none;
					border: none;
					outline: none;
					background: none;

					display: block;
					width: 100%;
					padding: 10px 15px;
					border-radius: 8px 0px 0px 8px;
					
					color: #333;
					font-size: 18px;

					box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
					background-color: #F3F3F3;

					transition: 0.4s;

					&::placeholder {
						color: #888;
						transition: 0.4s;
					}
				}
				
				input[type="submit"] {
					appearance: none;
					border: none;
					outline: none;
					background: none;

					display: block;
					padding: 10px 15px;
					border-radius: 0px 8px 8px 0px;

					background-color: #333;

					color: #FFF;
					font-size: 18px;
					font-weight: 700;
				}
			}
		}
	}
}
</style>
