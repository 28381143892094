import firebase from "firebase/app";
import "firebase/database";

const config = {
    apiKey: "AIzaSyAjgFRICKdTp4_WkOtHv_izvalFtD_FKm4",
    authDomain: "trinitysupportchat.firebaseapp.com",
    databaseURL: "https://trinitysupportchat-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "trinitysupportchat",
    storageBucket: "trinitysupportchat.appspot.com",
    messagingSenderId: "584040435816",
    appId: "1:584040435816:web:8e1ea092a125ce98942bdd",
    measurementId: "G-C2XWF23B6W"
  };

const db = firebase.initializeApp(config);
export default db;